import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import the required CSS for Owl Carousel
import 'owl.carousel/dist/assets/owl.theme.default.css'; // Import the default theme CSS

const HeaderCarousel = () => {
    const carouselItems = [
        {
            imgSrc: 'img/carousel-1.jpg',
            title: 'Welcome To NodeCurb',
            subtitle: 'Innovative Software & Web Development Solutions',
            description: 'NodeCurb specializes in delivering cutting-edge software and web development services to help businesses thrive in the digital age. Our team of experts ensures high-quality, scalable solutions tailored to your needs.',
        },
        {
            imgSrc: 'img/carousel-2.jpg',
            title: 'Welcome To NodeCurb',
            subtitle: 'Empowering Businesses with Custom Software Solutions',
            description: 'At NodeCurb, we transform your ideas into powerful software applications, designed to enhance efficiency, improve workflows, and drive business success. Partner with us for top-tier development services.',
        },
        
    ];

    return (
        <OwlCarousel className="header-carousel" items={1} autoplay loop nav  navText={[
            '<button class="text-white" style="position: absolute; width: 60px; height: 60px; border-radius: 10px; background: var(--bs-primary); font-size: 26px;  align-items: center; justify-content: center;"><i class="bi bi-arrow-left"></i></button>',
            '<button class="text-white" style="position: absolute; width: 60px; height: 60px; border-radius: 10px; background: var(--bs-primary); font-size: 26px; align-items: center; justify-content: center;"><i class="bi bi-arrow-right"></i> </button>',
        ]}>
            {carouselItems.map((item, index) => (
                <div className="header-carousel-item" key={index}>
                    <img src={item.imgSrc} className="img-fluid w-100" alt="Image" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row gy-0 gx-5">
                                <div className="col-lg-0 col-xl-5"></div>
                                <div className="col-xl-7 animated fadeInLeft">
                                    <div className="text-sm-center text-md-end">
                                        <h4 className="text-primary  fw-bold mb-4">{item.title}</h4>
                                        <h1 className="display-4 text-uppercase text-white mb-4">{item.subtitle}</h1>
                                        <p className="mb-5 fs-5">{item.description}</p>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
    );
};

export default HeaderCarousel;
