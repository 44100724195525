import React from 'react'

const Offer = () => {
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Our Offer</h4>
                    <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-primary">Our offer</li>
                    </ol>
                </div>
            </div>
            {/* <!-- Header End --> */}

            <div className="container-fluid offer-section pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Offer</h4>
                        <h1 className="display-5 mb-4">Benefits We Offer at NodeCurb</h1>
                        <p className="mb-0">
                            At NodeCurb, we empower your business with cutting-edge software, web, and mobile solutions. From streamlined project development to flexible scaling options, we deliver technology that drives your success.
                        </p>
                    </div>
                    <div className="row g-5 align-items-center">
                        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="nav nav-pills bg-light rounded p-5">
                                <a className="accordion-link p-4 active mb-4" data-bs-toggle="pill" href="#collapseOne">
                                    <h5 className="mb-0">Custom Software for Your Projects</h5>
                                </a>
                                <a className="accordion-link p-4 mb-4" data-bs-toggle="pill" href="#collapseTwo">
                                    <h5 className="mb-0">Scalable Solutions for Growing Businesses</h5>
                                </a>
                                <a className="accordion-link p-4 mb-4" data-bs-toggle="pill" href="#collapseThree">
                                    <h5 className="mb-0">Efficient Mobile App Development</h5>
                                </a>
                                <a className="accordion-link p-4 mb-0" data-bs-toggle="pill" href="#collapseFour">
                                    <h5 className="mb-0">Comprehensive Web Development Services</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.4s">
                            <div className="tab-content">
                                <div id="collapseOne" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-1.jpg" className="img-fluid w-100 rounded" alt="Custom Software" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Tailored Software for Your Needs</h1>
                                            <p className="mb-4">We provide custom-built software solutions that align with your business goals and help you operate efficiently in the competitive market.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseTwo" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-2.jpg" className="img-fluid w-100 rounded" alt="Scalable Solutions" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Flexible Solutions for Growth</h1>
                                            <p className="mb-4">Our scalable technology solutions ensure that your business can grow seamlessly, with the infrastructure to support expansion.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseThree" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-3.jpg" className="img-fluid w-100 rounded" alt="Mobile App Development" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Mobile Solutions on the Go</h1>
                                            <p className="mb-4">With our expertise in mobile app development, we create user-friendly apps that work across multiple platforms to deliver superior mobile experiences.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseFour" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-4.jpg" className="img-fluid w-100 rounded" alt="Web Development" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Web Development for the Digital Age</h1>
                                            <p className="mb-4">From design to development, our web solutions enhance your digital presence and provide a seamless online experience for your users.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Offer End --> */}
        </>
    )
}

export default Offer