import React from 'react'

const Service = () => {
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Our Services</h4>
                    <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-primary">Service</li>
                    </ol>
                </div>
            </div>
            {/* <!-- Header End --> */}
            {/* // < !--Navbar & Hero End-- > */}


            {/* // < !--Services Start-- > */}
            <div className="container-fluid service pb-5 mt-5">
                <div className="container pb-5">
                    <div
                        className="text-center mx-auto pb-5 wow fadeInUp"
                        data-wow-delay="0.2s"
                        style={{ maxWidth: '800px' }}
                    >
                        <h4 className="text-primary">Our Services</h4>
                        <h1 className="display-5 mb-4">Professional Solutions for Digital Success</h1>
                        <p className="mb-0">
                            At NodeCurb, we offer a wide range of services to help businesses thrive in the
                            digital age. From software development to marketing strategies, we provide
                            tailored solutions to meet your specific needs.
                        </p>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/programming.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Custom Software Development"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Custom Software Development
                                    </a>
                                    <p className="mb-4">
                                        Our team creates custom software solutions tailored to your business
                                        requirements, ensuring efficiency and scalability.
                                    </p>
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/web-developement.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Web Development"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Web Development
                                    </a>
                                    <p className="mb-4">
                                        We specialize in building responsive, user-friendly websites that are
                                        designed to engage and convert your audience.
                                    </p>
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/app-developement.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Mobile App Development"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Mobile App Development
                                    </a>
                                    <p className="mb-4">
                                        We develop high-performance mobile apps tailored to your business needs
                                        for both iOS and Android platforms.
                                    </p>
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/ui-ux.jpg"
                                        className="img-fluid rounded-top w-100"
                                        alt="UI/UX Design"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        UI/UX Design
                                    </a>
                                    <p className="mb-4">
                                        Our design experts craft intuitive and visually appealing user interfaces
                                        that deliver seamless user experiences.
                                    </p>
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/digital-marketing.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Digital Marketing"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Digital Marketing
                                    </a>
                                    <p className="mb-4">
                                        We offer comprehensive digital marketing strategies to boost your online
                                        presence and drive growth.
                                    </p>
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/buisnessman.jpg"
                                        className="img-fluid rounded-top w-100"
                                        alt="IT Consulting"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        IT Consulting
                                    </a>
                                    <p className="mb-4">
                                        Our experts provide IT consulting services to help optimize your
                                        technology investments and business processes.
                                    </p>
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Services End-- > */}

        </>
    )
}

export default Service