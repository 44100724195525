import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>

            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">About Us</h4>
                    <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-primary">About</li>
                    </ol>
                </div>
            </div>

            <div class="container-fluid about py-5">
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-xl-7 wow fadeInLeft" data-wow-delay="0.2s">
                            <div>
                                <h4 class="text-primary">About NodeCurb</h4>
                                <h1 class="display-5 mb-4">Transforming Ideas Into Digital Solutions</h1>
                                <p class="mb-4">At NodeCurb, we specialize in delivering custom software and web development solutions that empower businesses to achieve their digital goals. Our expertise helps companies innovate, grow, and thrive in the tech-driven world.</p>
                                <div class="row g-4">
                                    <div class="col-md-6 col-lg-6 col-xl-6">
                                        <div class="d-flex">
                                            <div><i class="fas fa-code fa-3x text-primary"></i></div>
                                            <div class="ms-4">
                                                <h4>Custom Development</h4>
                                                <p>We build tailored software and web solutions to meet your unique business needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6">
                                        <div class="d-flex">
                                            <div><i class="fas fa-cogs fa-3x text-primary"></i></div>
                                            <div class="ms-4">
                                                <h4>Years of Expertise</h4>
                                                <p>Our experienced team ensures that every project is delivered with precision and excellence.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <Link to="/contact" class="btn btn-primary rounded-pill py-3 px-5 flex-shrink-0">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 wow fadeInRight" data-wow-delay="0.2s">
                            <div class="rounded position-relative overflow-hidden">
                                <img src="img/about_.png" class="img-fluid rounded w-100" alt="NodeCurb Development" />
                                <div class="" style={{ position: 'absolute', top: '-15px', right: '-15px' }}>
                                    <img src="img/about_.png" class="img-fluid rounded-bottom w-100" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- About End --> */}

            {/* <!-- Features Start --> */}
            <div className="container-fluid feature pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Core Features</h4>
                        <h1 className="display-5 mb-4">Empowering Your Business with Cutting-Edge Web and Mobile Solutions</h1>
                        <p className="mb-0">At NodeCurb, we are committed to delivering high-quality web and mobile applications designed with core principles of responsiveness, security, and scalability. Discover how our expertise can elevate your digital presence.</p>
                    </div>
                    <div className="row g-4">
                        {/* <!-- Responsiveness --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-mobile-alt fa-4x text-primary"></i>
                                </div>
                                <h4>Responsive Design</h4>
                                <p className="mb-4">Our solutions are built with a mobile-first approach, ensuring that your website and applications provide an optimal viewing experience across all devices and screen sizes. From desktop to smartphone, your users will enjoy a seamless and engaging experience.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a> */}
                            </div>
                        </div>
                        {/* <!-- Security --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-shield-alt fa-4x text-primary"></i>
                                </div>
                                <h4>Robust Security</h4>
                                <p className="mb-4">We prioritize the security of your digital assets with advanced protection measures. Our solutions include secure authentication, data encryption, and regular security audits to safeguard your applications and user data from potential threats and vulnerabilities.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Discover More</a> */}
                            </div>
                        </div>
                        {/* <!-- Scalability --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-expand-arrows-alt fa-4x text-primary"></i>
                                </div>
                                <h4>Scalable Solutions</h4>
                                <p className="mb-4">Our development approach ensures that your applications can grow with your business. We design scalable solutions that can handle increased traffic and expanded functionalities without compromising performance, allowing you to scale effortlessly as your needs evolve.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Explore More</a> */}
                            </div>
                        </div>
                        {/* <!-- Performance --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-tachometer-alt fa-4x text-primary"></i>
                                </div>
                                <h4>High Performance</h4>
                                <p className="mb-4">We optimize our solutions for peak performance, ensuring fast load times and smooth user interactions. By employing the latest technologies and best practices, we deliver applications that perform exceptionally under various conditions.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Features End --> */}

            {/* <!-- Team Start --> */}
            <div className="container-fluid team pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Team</h4>
                        <h1 className="display-5 mb-4">Meet Our Experts</h1>
                        <p className="mb-0">
                            Our team of skilled professionals is dedicated to delivering top-notch web and mobile app solutions.
                            With expertise in various technologies, we are here to bring your ideas to life.
                        </p>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-images/blank.jpg" className="img-fluid" alt="MAHESH VERMA" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">MAHESH VERMA</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0"  href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-images/blank.jpg" className="img-fluid" alt="ASHISH MAURYA" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">ASHISH MAURYA</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0" href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-3.jpg" className="img-fluid" alt="Michael Lee" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">ROHIT SHARMA</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0"  href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-4.jpg" className="img-fluid" alt="Emily Johnson" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">GURSHA KASAUDHAN</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0"  href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Team End --> */}
        </>
    )
}

export default About