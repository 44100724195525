import React from 'react'

const OurFeature = () => {
  return (
    <>
    <div class="container-fluid bg-breadcrumb">
            <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Our Features</h4>
                <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active text-white">Our Features</li>
                </ol>
            </div>
        </div>
        <div className="container-fluid feature pb-5 mt-5">
            <div className="container pb-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                    <h4 className="text-primary">Our Features</h4>
                    <h1 className="display-5 mb-4">Empowering Businesses with Custom Software, Web, and Mobile Solutions</h1>
                    <p className="mb-0">At NodeCurb, we specialize in connecting businesses, ideas, and people through innovative solutions that make a lasting impact in the digital world.</p>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-laptop-code fa-4x text-primary"></i>
                            </div>
                            <h4>Custom Software Development</h4>
                            <p className="mb-4">Tailored software solutions built to streamline your business operations and drive growth.</p>
                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-globe fa-4x text-primary"></i>
                            </div>
                            <h4>Web Development</h4>
                            <p className="mb-4">Responsive, fast, and secure websites to enhance your online presence and engage your audience.</p>
                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-mobile-alt fa-4x text-primary"></i>
                            </div>
                            <h4>Mobile App Development</h4>
                            <p className="mb-4">Native and cross-platform mobile apps designed to deliver the best user experiences on the go.</p>
                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-chart-line fa-4x text-primary"></i>
                            </div>
                            <h4>Business Analytics</h4>
                            <p className="mb-4">Data-driven insights to help you make informed decisions and scale your business with confidence.</p>
                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default OurFeature