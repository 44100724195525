import React, { useEffect } from 'react'
import HeaderCarousel from './HeaderCarousel '
import { Link } from 'react-router-dom';

const HomePage = () => {
    useEffect(() => {
        // Initialize Owl Carousel
        const $ = window.$; // Ensure jQuery is available
        $('.owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            responsive: {
                0: { items: 1 },
                600: { items: 2 },
                1000: { items: 3 },
            },
        });
    }, []);
    return (
        <>
            <HeaderCarousel />
            {/* <!-- Abvout Start --> */}
            <div class="container-fluid about py-5">
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-xl-7 wow fadeInLeft" data-wow-delay="0.2s">
                            <div>
                                <h4 class="text-primary">About NodeCurb</h4>
                                <h1 class="display-5 mb-4">Transforming Ideas Into Digital Solutions</h1>
                                <p class="mb-4">At NodeCurb, we specialize in delivering custom software and web development solutions that empower businesses to achieve their digital goals. Our expertise helps companies innovate, grow, and thrive in the tech-driven world.</p>
                                <div class="row g-4">
                                    <div class="col-md-6 col-lg-6 col-xl-6">
                                        <div class="d-flex">
                                            <div><i class="fas fa-code fa-3x text-primary"></i></div>
                                            <div class="ms-4">
                                                <h4>Custom Development</h4>
                                                <p>We build tailored software and web solutions to meet your unique business needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6">
                                        <div class="d-flex">
                                            <div><i class="fas fa-cogs fa-3x text-primary"></i></div>
                                            <div class="ms-4">
                                                <h4>Years of Expertise</h4>
                                                <p>Our experienced team ensures that every project is delivered with precision and excellence.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <Link to="/contact" class="btn btn-primary rounded-pill py-3 px-5 flex-shrink-0">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 wow fadeInRight" data-wow-delay="0.2s">
                            <div class="rounded position-relative overflow-hidden">
                                <img src="img/about_.png" class="img-fluid rounded w-100" alt="NodeCurb Development" />
                                <div class="" style={{ position: 'absolute', top: '-15px', right: '-15px' }}>
                                    <img src="img/about_.png" class="img-fluid rounded-bottom w-100" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- About End --> */}

            {/* <!-- Services Start --> */}
            <div className="container-fluid service pb-5 mt-5">
                <div className="container pb-5">
                    <div
                        className="text-center mx-auto pb-5 wow fadeInUp"
                        data-wow-delay="0.2s"
                        style={{ maxWidth: '800px' }}
                    >
                        <h4 className="text-primary">Our Services</h4>
                        <h1 className="display-5 mb-4">Professional Solutions for Digital Success</h1>
                        <p className="mb-0">
                            At NodeCurb, we offer a wide range of services to help businesses thrive in the
                            digital age. From software development to marketing strategies, we provide
                            tailored solutions to meet your specific needs.
                        </p>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/programming.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Custom Software Development"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Custom Software Development
                                    </a>
                                    <p className="mb-4">
                                        {/* Our team creates custom software solutions tailored to your business */}
                                        requirements, ensuring efficiency and scalability.
                                    </p>
                                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn MRead                                </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/web-developement.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Web Development"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Web Development
                                    </a>
                                    <p className="mb-4">
                                        {/* We specialize in building responsive, user-friendly websites that are */}
                                        designed to engage and convert your audience.
                                    </p>
                                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn MRead                                </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/app-developement.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Mobile App Development"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Mobile App Development
                                    </a>
                                    <p className="mb-4">
                                        {/* We develop high-performance mobile apps tailored to your business needs */}
                                        for both iOS and Android platforms.
                                    </p>
                                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn MRead                                </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/ui-ux.jpg"
                                        className="img-fluid rounded-top w-100"
                                        alt="UI/UX Design"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        UI/UX Design
                                    </a>
                                    <p className="mb-4">
                                        {/* Our design experts craft intuitive and visually appealing user interfaces */}
                                        that deliver seamless user experiences.
                                    </p>
                                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn MRead                                </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/digital-marketing.png"
                                        className="img-fluid rounded-top w-100"
                                        alt="Digital Marketing"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        Digital Marketing
                                    </a>
                                    <p className="mb-4">
                                        {/* We offer comprehensive digital marketing strategies to boost your online */}
                                        presence and drive growth.
                                    </p>
                                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn MRead                                </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img
                                        src="img/buisnessman.jpg"
                                        className="img-fluid rounded-top w-100"
                                        alt="IT Consulting"
                                    />
                                </div>
                                <div className="rounded-bottom p-4">
                                    <a href="#" className="h4 d-inline-block mb-4">
                                        IT Consulting
                                    </a>
                                    <p className="mb-4">
                                        {/* Our experts provide IT consulting services to help optimize your */}
                                        technology investments and business processes.
                                    </p>
                                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Learn MRead                                </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Services End --> */}

            {/* <!-- Features Start --> */}
            <div className="container-fluid feature pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Core Features</h4>
                        <h1 className="display-5 mb-4">Empowering Your Business with Cutting-Edge Web and Mobile Solutions</h1>
                        <p className="mb-0">At NodeCurb, we are committed to delivering high-quality web and mobile applications designed with core principles of responsiveness, security, and scalability. Discover how our expertise can elevate your digital presence.</p>
                    </div>
                    <div className="row g-4">
                        {/* <!-- Responsiveness --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-mobile-alt fa-4x text-primary"></i>
                                </div>
                                <h4>Responsive Design</h4>
                                <p className="mb-4">Our solutions are built with a mobile-first approach, ensuring that your website and applications provide an optimal viewing experience across all devices and screen sizes. From desktop to smartphone, your users will enjoy a seamless and engaging experience.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a> */}
                            </div>
                        </div>
                        {/* <!-- Security --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-shield-alt fa-4x text-primary"></i>
                                </div>
                                <h4>Robust Security</h4>
                                <p className="mb-4">We prioritize the security of your digital assets with advanced protection measures. Our solutions include secure authentication, data encryption, and regular security audits to safeguard your applications and user data from potential threats and vulnerabilities.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Discover More</a> */}
                            </div>
                        </div>
                        {/* <!-- Scalability --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-expand-arrows-alt fa-4x text-primary"></i>
                                </div>
                                <h4>Scalable Solutions</h4>
                                <p className="mb-4">Our development approach ensures that your applications can grow with your business. We design scalable solutions that can handle increased traffic and expanded functionalities without compromising performance, allowing you to scale effortlessly as your needs evolve.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Explore More</a> */}
                            </div>
                        </div>
                        {/* <!-- Performance --> */}
                        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-4">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fas fa-tachometer-alt fa-4x text-primary"></i>
                                </div>
                                <h4>High Performance</h4>
                                <p className="mb-4">We optimize our solutions for peak performance, ensuring fast load times and smooth user interactions. By employing the latest technologies and best practices, we deliver applications that perform exceptionally under various conditions.</p>
                                {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Features End --> */}


            {/* <!-- Offer Start --> */}
            <div className="container-fluid offer-section pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Offer</h4>
                        <h1 className="display-5 mb-4">Benefits We Offer at NodeCurb</h1>
                        <p className="mb-0">
                            At NodeCurb, we empower your business with cutting-edge software, web, and mobile solutions. From streamlined project development to flexible scaling options, we deliver technology that drives your success.
                        </p>
                    </div>
                    <div className="row g-5 align-items-center">
                        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="nav nav-pills bg-light rounded p-5">
                                <a className="accordion-link p-4 active mb-4" data-bs-toggle="pill" href="#collapseOne">
                                    <h5 className="mb-0">Custom Software for Your Projects</h5>
                                </a>
                                <a className="accordion-link p-4 mb-4" data-bs-toggle="pill" href="#collapseTwo">
                                    <h5 className="mb-0">Scalable Solutions for Growing Businesses</h5>
                                </a>
                                <a className="accordion-link p-4 mb-4" data-bs-toggle="pill" href="#collapseThree">
                                    <h5 className="mb-0">Efficient Mobile App Development</h5>
                                </a>
                                <a className="accordion-link p-4 mb-0" data-bs-toggle="pill" href="#collapseFour">
                                    <h5 className="mb-0">Comprehensive Web Development Services</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.4s">
                            <div className="tab-content">
                                <div id="collapseOne" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-1.jpg" className="img-fluid w-100 rounded" alt="Custom Software" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Tailored Software for Your Needs</h1>
                                            <p className="mb-4">We provide custom-built software solutions that align with your business goals and help you operate efficiently in the competitive market.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseTwo" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-2.jpg" className="img-fluid w-100 rounded" alt="Scalable Solutions" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Flexible Solutions for Growth</h1>
                                            <p className="mb-4">Our scalable technology solutions ensure that your business can grow seamlessly, with the infrastructure to support expansion.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseThree" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-3.jpg" className="img-fluid w-100 rounded" alt="Mobile App Development" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Mobile Solutions on the Go</h1>
                                            <p className="mb-4">With our expertise in mobile app development, we create user-friendly apps that work across multiple platforms to deliver superior mobile experiences.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseFour" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-md-7">
                                            <img src="img/offer-4.jpg" className="img-fluid w-100 rounded" alt="Web Development" />
                                        </div>
                                        <div className="col-md-5">
                                            <h1 className="display-5 mb-4">Web Development for the Digital Age</h1>
                                            <p className="mb-4">From design to development, our web solutions enhance your digital presence and provide a seamless online experience for your users.</p>
                                            <a className="btn btn-primary rounded-pill py-2 px-4" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Offer End --> */}
            {/* <!-- Blog Start --> */}



            <div className="container-fluid blog pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Blog & News</h4>
                        <h1 className="display-5 mb-4">Insights on Web Software & Mobile App Development</h1>
                        <p className="mb-0">
                            Stay updated with the latest trends, tips, and best practices in web software and mobile app development.
                        </p>
                    </div>
                    <div className="owl-carousel blog-carousel wow fadeInUp" data-wow-delay="0.2s">
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-1.jpg" className="img-fluid w-100 rounded" alt="Web Development" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Latest Web Technologies</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">Exploring React and Its Ecosystem</a>
                            <p className="mb-4">Discover the powerful features of React and how it can enhance your web development projects...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-1.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-2.jpg" className="img-fluid w-100 rounded" alt="Mobile Development" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Mobile App Trends</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">The Rise of Progressive Web Apps</a>
                            <p className="mb-4">Learn how progressive web apps bridge the gap between web and mobile applications...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-2.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-3.jpg" className="img-fluid w-100 rounded" alt="Software Development" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Software Engineering</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">Agile Development Methodologies</a>
                            <p className="mb-4">Explore the benefits of agile methodologies in modern software development practices...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-3.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-4.jpg" className="img-fluid w-100 rounded" alt="Tech Innovations" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Tech Innovations</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">AI in Mobile Apps</a>
                            <p className="mb-4">Uncover how artificial intelligence is transforming mobile app development and user experiences...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-4.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- Blog End --> */}


            {/* <!-- FAQs Start --> */}
            <div className="container-fluid faq-section pb-5">
                <div className="container pb-5 overflow-hidden">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">FAQs</h4>
                        <h1 className="display-5 mb-4">Frequently Asked Questions</h1>
                        <p className="mb-0">
                            Explore the answers to some of the most common questions regarding web software and mobile app development.
                        </p>
                    </div>
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="accordion accordion-flush bg-light rounded p-5" id="accordionFlushSection">
                                <div className="accordion-item rounded-top">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            What is the difference between web development and mobile app development?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushSection">
                                        <div className="accordion-body">
                                            Web development focuses on building applications that run on web browsers, while mobile app development involves creating software applications specifically designed for mobile devices.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            What programming languages are commonly used in web development?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushSection">
                                        <div className="accordion-body">
                                            Common languages include HTML, CSS, JavaScript, PHP, Python, and Ruby, among others, depending on the specific technology stack.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            How long does it take to develop a mobile app?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushSection">
                                        <div className="accordion-body">
                                            The timeline can vary widely based on app complexity, features, and platforms. On average, it can take anywhere from a few weeks to several months.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            What is an MVP in app development?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushSection">
                                        <div className="accordion-body">
                                            An MVP, or Minimum Viable Product, is a version of a product with just enough features to satisfy early customers and provide feedback for future development.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            How much does it cost to develop a web application?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushSection">
                                        <div className="accordion-body">
                                            Development costs can vary greatly depending on the project's scope, features, and technology stack. Simple applications may start from a few thousand dollars, while complex solutions can exceed hundreds of thousands.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item rounded-bottom">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                            What is the role of a UX/UI designer in app development?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushSection">
                                        <div className="accordion-body">
                                            UX/UI designers focus on creating user-friendly interfaces and experiences that make applications easy to use and visually appealing, enhancing overall user satisfaction.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="bg-primary rounded">
                                <img src="img/faq.png" className="img-fluid w-100" alt="Development" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FAQs End --> */}
            {/* <!-- Team Start --> */}
            <div className="container-fluid team pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Team</h4>
                        <h1 className="display-5 mb-4">Meet Our Experts</h1>
                        <p className="mb-0">
                            Our team of skilled professionals is dedicated to delivering top-notch web and mobile app solutions.
                            With expertise in various technologies, we are here to bring your ideas to life.
                        </p>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-images/blank.jpg" className="img-fluid" alt="MAHESH VERMA" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">MAHESH VERMA</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0" href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-images/blank.jpg" className="img-fluid" alt="ASHISH MAURYA" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">ASHISH MAURYA</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0" href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-3.jpg" className="img-fluid" alt="Michael Lee" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">ROHIT SHARMA</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0" href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="team-item">
                                {/* <div className="team-img">
                                    <img src="img/team-4.jpg" className="img-fluid" alt="Emily Johnson" />
                                </div> */}
                                <div className="team-title">
                                    <h4 className="mb-0">GURSHA KASAUDHAN</h4>
                                    <p className="mb-0">Founder</p>
                                </div>
                                <div className="team-icon">
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.facebook.com/profile.php?id=61565602063416"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-primary btn-sm-square rounded-circle me-0" href="https://www.instagram.com/nodecurb_it_solutions"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
        </>
    )
}

export default HomePage