import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            {/* <!-- Topbar Start --> */}
            <div class="container-fluid topbar bg-light px-5 d-none d-lg-block">
                <div class="row gx-0 align-items-center">
                    <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div class="d-flex flex-wrap">
                            <a href="#" class="text-muted small me-4"><i class="fas fa-map-marker-alt text-primary me-2"></i> Noida, Uttar Pradesh, India</a>
                            <a href="tel:+01234567890" class="text-muted small me-4"><i class="fas fa-phone-alt text-primary me-2"></i>+91 7081257988</a>
                            <a href="mailto:example@gmail.com" class="text-muted small me-0"><i class="fas fa-envelope text-primary me-2"></i><a href="mailto:contact@nodecurb.com">contact@nodecurb.com</a></a>
                        </div>
                    </div>
                    <div class="col-lg-4 text-center text-lg-end">
                        <div class="d-inline-flex align-items-center" style={{ height: '45px' }}>
                            <a href="#"><small class="me-3 text-dark"><i class="fa fa-user text-primary me-2"></i>Register</small></a>
                            <a href="#"><small class="me-3 text-dark"><i class="fa fa-sign-in-alt text-primary me-2"></i>Login</small></a>
                            {/* <div class="dropdown">
                                <a href="#" class="dropdown-toggle text-dark" data-bs-toggle="dropdown"><small><i class="fa fa-home text-primary me-2"></i> My Dashboard</small></a>
                                <div class="dropdown-menu rounded">
                                    <Link to="/" class="dropdown-item"><i class="fas fa-user-alt me-2"></i> My Profile</Link>
                                    <Link to="/" class="dropdown-item"><i class="fas fa-comment-alt me-2"></i> Inbox</Link>
                                    <Link to="/" class="dropdown-item"><i class="fas fa-bell me-2"></i> Notifications</Link>
                                    <Link to="/" class="dropdown-item"><i class="fas fa-cog me-2"></i> Account Settings</Link>
                                    <Link to="/" class="dropdown-item"><i class="fas fa-power-off me-2"></i> Log Out</Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}

            {/* <!-- Navbar & Hero Start --> */}
            <div class="container-fluid position-relative p-0">
                <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                    <a href="" class="navbar-brand p-0">
                        <h1 class="text-primary"><img src="/img/logo_.png" alt="Logo" /></h1>
                      
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="fa fa-bars"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ms-auto py-0">
                            <Link to="/" class="nav-item nav-link">Home</Link>
                            <Link to="/about" class="nav-item nav-link">About</Link>
                            <Link to="/service" class="nav-item nav-link">Services</Link>
                            <Link to="/blog" class="nav-item nav-link">Blogs</Link>
                            <div class="nav-item dropdown">
                                <a href="#" class="nav-link" data-bs-toggle="dropdown">
                                    <span class="dropdown-toggle">Pages</span>
                                </a>
                                <div class="dropdown-menu m-0">
                                    <Link to="/feature" class="dropdown-item">Our Features</Link>
                                    <Link to="/offer" class="dropdown-item">Our Offer</Link>
                                </div>
                            </div>
                            <Link to="/contact" class="nav-item nav-link">Contact Us</Link>
                        </div>
                        <a href="#home" class="btn btn-primary rounded-pill py-2 px-4 my-3 my-lg-0 flex-shrink-0">Get Quote</a>
                    </div>
                </nav>

                
            </div>
            {/* <!-- Navbar & Hero End --> */}
        </>
    )
}

export default Header