import React, { useEffect } from 'react'

const Blog = () => {
    useEffect(() => {
        // Initialize Owl Carousel
        const $ = window.$; // Ensure jQuery is available
        $('.owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            responsive: {
                0: { items: 1 },
                600: { items: 2 },
                1000: { items: 3 },
            },
        });
    }, []);
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Our Blog</h4>
                    <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-primary">Blog</li>
                    </ol>
                </div>
            </div>
            {/* <!-- Header End --> */}

            {/* // < !--Navbar & Hero End-- > */}


            {/* // < !--Blog Start-- > */}
            <div className="container-fluid blog pb-5 mt-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Blog & News</h4>
                        <h1 className="display-5 mb-4">Insights on Web Software & Mobile App Development</h1>
                        <p className="mb-0">
                            Stay updated with the latest trends, tips, and best practices in web software and mobile app development.
                        </p>
                    </div>
                    <div className="owl-carousel blog-carousel wow fadeInUp" data-wow-delay="0.2s">
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-1.jpg" className="img-fluid w-100 rounded" alt="Web Development" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Latest Web Technologies</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">Exploring React and Its Ecosystem</a>
                            <p className="mb-4">Discover the powerful features of React and how it can enhance your web development projects...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-1.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-2.jpg" className="img-fluid w-100 rounded" alt="Mobile Development" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Mobile App Trends</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">The Rise of Progressive Web Apps</a>
                            <p className="mb-4">Learn how progressive web apps bridge the gap between web and mobile applications...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-2.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-3.jpg" className="img-fluid w-100 rounded" alt="Software Development" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Software Engineering</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">Agile Development Methodologies</a>
                            <p className="mb-4">Explore the benefits of agile methodologies in modern software development practices...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-3.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="img/service-4.jpg" className="img-fluid w-100 rounded" alt="Tech Innovations" />
                                <div className="blog-title">
                                    <a href="#" className="btn">Tech Innovations</a>
                                </div>
                            </div>
                            <a href="#" className="h4 d-inline-block mb-3">AI in Mobile Apps</a>
                            <p className="mb-4">Uncover how artificial intelligence is transforming mobile app development and user experiences...</p>
                            <div className="d-flex align-items-center">
                                {/* <img src="img/testimonial-4.jpg" className="img-fluid rounded-circle" style={{ width: '60px', height: '60px' }} alt="Author" /> */}
                                <div className="ms-3">
                                    {/* <h5>Admin</h5>
                                    <p className="mb-0">October 9, 2025</p> */}
                                    <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Blog End-- > */}
        </>
    )
}

export default Blog