import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            {/* <!-- Footer Start --> */}
            <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
                <div class="container py-5 border-start-0 border-end-0" style={{ border: '1px solid', borderColor: 'rgb(255, 255, 255, 0.08)' }}>
                    <div class="row g-5">
                        <div class="col-md-6 col-lg-6 col-xl-4">
                            <div class="footer-item">
                                <a href="index.html" class="pt-0">
                                    {/* <h4 class="text-white"><i class="fas fa-search-dollar me-3"></i>Stocker</h4> */}
                                    <img src="/img/logo_.png" alt="Logo" style={{height:'160px',widht:'50%'}}/>
                                </a>
                                <p class="mb-4 text-white">At NodeCurb, we transform your ideas into powerful software applications, designed to enhance efficiency, improve workflows. </p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-2">
                            <div class="footer-item">
                                <h4 class="text-white mb-4">Quick Links</h4>
                                <Link to="/about" className='text-white'><i class="fas fa-angle-right me-2"></i> About Us</Link>
                                <Link to="/feature" className='text-white'><i class="fas fa-angle-right me-2"></i> Feature</Link>
                                <Link to="/service" className='text-white'><i class="fas fa-angle-right me-2"></i> Offer</Link>
                                <Link to="/offer" className='text-white'><i class="fas fa-angle-right me-2"></i> Tickets</Link>
                                <Link to="/blog" className='text-white'><i class="fas fa-angle-right me-2"></i> Blog</Link>
                                <Link to="/contact" className='text-white'><i class="fas fa-angle-right me-2"></i> Contact us</Link>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-3">
                            <div class="footer-item">
                                <h4 class="text-white mb-4">Support</h4>
                                <Link to="/"  className='text-white'><i class="fas fa-angle-right me-2"></i> Privacy Policy</Link>
                                <Link to="/"  className='text-white'><i class="fas fa-angle-right me-2"></i> Terms & Conditions</Link>
                                <Link to="/"  className='text-white'><i class="fas fa-angle-right me-2"></i> Disclaimer</Link>
                                <Link to="/"  className='text-white'><i class="fas fa-angle-right me-2"></i> Support</Link>
                                <Link to="/"  className='text-white'><i class="fas fa-angle-right me-2"></i> FAQ</Link>
                                <Link to="/"  className='text-white'><i class="fas fa-angle-right me-2"></i> Help</Link>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-3">
                            <div class="footer-item">
                                <h4 class="text-white mb-4">Contact Info</h4>
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-map-marker-alt text-white me-3"></i>
                                    <p class="text-white mb-0">Noida, Uttar Pradesh, India</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-envelope text-white me-3"></i>
                                    <p class="text-white mb-0"><a href="support@nodecurb.com" className="text-white">support@nodecurb.com</a></p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="fa fa-phone-alt text-white me-3"></i>
                                    <p class="text-white mb-0"><a href="tel:+91 7081257988">7081257988</a></p>
                                </div>
                               
                                <div class="d-flex">
                                    <a class="btn btn-primary btn-sm-square rounded-circle me-3" href="sttps://www.facebook.com/profile.php?id=61565602063416"><i class="fab fa-facebook-f text-white"></i></a>
                                    <a class="btn btn-primary btn-sm-square rounded-circle me-3" href="https://x.com/nodecurb?t=0nWIlknSxoSaQCTx0w1zbw&s=09"><i class="fab fa-twitter text-white"></i></a>
                                    <a class="btn btn-primary btn-sm-square rounded-circle me-3" href="https://www.instagram.com/nodecurb_it_solutions"><i class="fab fa-instagram text-white"></i></a>
                                    <a class="btn btn-primary btn-sm-square rounded-circle me-0" href="https://www.linkedin.com/in/nodecurb-it-solutions-4b6399329?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i class="fab fa-linkedin-in text-white"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}

            {/* <!-- Copyright Start --> */}
            {/* <div class="container-fluid copyright py-4">
                <div class="container">
                    <div class="row g-4 align-items-center">
                        <div class="col-md-6 text-center text-md-start mb-md-0">
                            <span class="text-body"><a href="#" class="border-bottom text-white"><i class="fas fa-copyright text-light me-2"></i>Your Site Name</a>, All right reserved.</span>
                        </div>
                        <div class="col-md-6 text-center text-md-end text-body">
                            Designed By <a class="border-bottom text-white" href="">Coders Cube</a>
                        </div>
                    </div>
                </div> */}
            {/* </div> */}
            {/* <!-- Copyright End --> */}

        </>
    )
}

export default Footer