import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import HomePage from './Components/HomePage';
import About from './Components/About';
import Contact from './Components/Contact';
import Service from './Components/Service';
import Blog from './Components/Blog';
import Error from './Components/Error';
import Offer from './Components/Offer';
import OurFeature from './Components/OurFeature';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />} >
            <Route index element={<HomePage />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/service' element={<Service />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/offer' element={<Offer />} />
            <Route path='/feature' element={<OurFeature />} />
          </Route>
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
